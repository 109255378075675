export type Discount = {
  ApplicationMethodId: number;
  CanStackAutomatically: boolean;
  Constraints: Constraint[];
  DiscountCode?: string;
  DiscountDescription: string;
  EndTime?: string;
  ExternalId: string;
  FirstTimeCustomerOnly: boolean | number;
  Friday?: boolean;
  Id: number;
  IgnoreNetTax: boolean;
  IsAvailableOnline: boolean;
  IsBundledDiscount: boolean;
  LocationRestrictions: number[];
  LoyaltyPointValue?: number;
  MaxRedemptions?: number | string;
  MenuDisplayRank?: number | null;
  Monday?: boolean;
  OnlineName?: string;
  PaymentRestrictions?: PaymentRestrictions;
  RedemptionLimit?: number | string;
  RequireManagerApproval: boolean;
  RestrictToGroupIds: number[];
  RestrictToSegmentIds: number[];
  Reward: Reward;
  Saturday?: boolean;
  SavedWithAdvancedOptions?: boolean;
  StartTime?: string;
  Sunday?: boolean;
  Thursday?: boolean;
  Tuesday?: boolean;
  ValidDateFrom: string;
  ValidDateTo: string;
  Wednesday?: boolean;
};

export type DiscountListItem = {
  ApplicationMethod: string;
  DiscDateFrom: string;
  DiscDateto: string;
  DiscountCode: string;
  DiscountDescription: string;
  Id: number;
  SpringBigDiscountId: string;
};

export type DiscountFormProps = {
  allDiscounts?: DiscountListItem[];
  brands: BrandRestriction[];
  canEdit: boolean;
  categories: CategoryRestriction[];
  constraint?: Constraint;
  constraintIndex?: number;
  customerTypes?: CustomerTypeRestriction[];
  discount: Discount;
  loading: boolean;
  navigate: (page: string) => void;
  products: ProductRestriction[];
  savedWithAdvancedOptions: boolean;
  segments?: SegmentRestriction[];
  setDiscount: (discount: Discount) => void;
  setLoading: (loading: boolean) => void;
  setOptions?: (showAdvanced: boolean) => void;
  strains: StrainRestriction[];
  tags: TagRestriction[];
  tiers: TierRestriction[];
  userLoyalties?: UserLoyaltyIntegrations;
  vendors: VendorRestriction[];
  weights: WeightRestriction[];
};

export type UserLoyaltyIntegrations = {
  useAlpineIq: boolean;
  useFyllo: boolean;
  useSpringBig: boolean;
};

export type DiscountRestrictionProps = {
  activeTab: number;
  brands: BrandRestriction[];
  canEdit: boolean;
  categories: CategoryRestriction[];
  constraint?: Constraint;
  constraintIndex?: number;
  discount: Discount;
  isConstraint?: boolean;
  loading: boolean;
  products: ProductRestriction[];
  savedWithAdvancedOptions: boolean;
  setDiscount: (discount: Discount) => void;
  setLoading: (loading: boolean) => void;
  startTabIndex: number;
  strains: StrainRestriction[];
  tags: TagRestriction[];
  tiers: TierRestriction[];
  vendors: VendorRestriction[];
  weights: WeightRestriction[];
};

export type Constraint = {
  DiscountConstraintId?: number;
  DiscountId: number;
  DiscountItemGroupTypeId?: number;
  IncludeNonCannabis: boolean;
  Products: any[];
  Restrictions: Restrictions;
  ThresholdMax?: number;
  ThresholdMin?: number;
  ThresholdTypeId?: number;
};

export type Reward = {
  ApplyToOnlyOneItem: boolean;
  CalculationMethodId: number;
  DiscountId?: number;
  DiscountRewardId?: number;
  DiscountValue: number;
  HasThreshold?: boolean;
  HighestOrLowest: 'high' | 'low';
  IncludeNonCannabis: boolean;
  ItemGroupTypeId: number;
  ManualDefaultApplyTo: number;
  Products: any[];
  Restrictions: Restrictions;
  ThresholdMax?: number;
  ThresholdMin?: number;
  ThresholdTypeId?: number;
};

export enum ThresholdType {
  NoThreshold = 0,
  NumberOfItems = 1,
  SpendingAmount = 2,
  ProductWeight = 3,
}

export enum DiscountFirstTimeCustomer {
  NoFirstTimeDiscount = 0,
  ByLocation = 1,
  AllLocations = 2,
}

export type Restriction = {
  IsExclusion: boolean;
  RestrictionIds: number[];
};

export type Restrictions = {
  Brand?: Restriction;
  Category?: Restriction;
  CustomerType?: Restriction;
  InventoryTag?: Restriction;
  Product?: Restriction;
  Strain?: Restriction;
  Tag?: Restriction;
  Tier?: Restriction;
  Vendor?: Restriction;
  Weight?: Restriction;
};

export type StrainRestriction = {
  Abbreviation: string;
  ExternalId?: number;
  isSelected: boolean;
  StrainAbbreviation: string;
  StrainDescription: string;
  StrainId: number;
  StrainName: string;
  StrainType: string;
};

export type BrandRestriction = {
  BrandId: number;
  BrandName: string;
  isDeletedBrandRestriction?: boolean;
  isSelected: boolean;
};

export type CategoryRestriction = {
  FlowerEquivalent?: number;
  isSelected: boolean;
  LeafLinkCategoryId?: number;
  LeafLinkSubCategoryId?: number;
  LeaflyCategory: string;
  MasterCategory: string;
  MMURAlternativeForms?: any[];
  MMURFormId?: number;
  ProductCategory: string;
  ProductCategoryId: number;
  ProductFlag: string;
  PurchaseLimitCategoryId?: number;
  RecFlowerEquivalent?: number;
  RegulatoryCategoryId?: number;
  SalesAccountId?: number;
  StateInventoryFlag: string;
  TaxCategories?: any[];
  WeedMapsCategoryId?: number;
  WeedMapsSubCategoryId?: number;
};

export type VendorRestriction = {
  Abbreviation: string;
  Address: string;
  City: string;
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string;
  IsActive: boolean;
  isSelected: boolean;
  Notes: string;
  PostalCode: string;
  State: string;
  VendorCode: string;
  VendorId: number;
  VendorName: string;
  VendorTypeId?: number;
};

export type WeightRestriction = {
  isSelected: boolean;
  weight: number;
};

export type CustomerTypeRestriction = {
  CustomerTypeId: string;
  CustomerTypeName: number;
  isSelected: boolean;
};

export type SegmentRestriction = {
  isSelected: boolean;
  Name: string;
  SegmentId: number;
};

export type PaymentRestrictions = {
  PayByBankSignupIncentive: boolean;
};

export type ProductRestriction = {
  Available: number;
  AvailableUnitAbbreviation: string;
  BrandId?: number;
  BrandName: string;
  Category: string;
  CategoryId?: number;
  ChargeCodeId?: number;
  Cultivator: string;
  InvId: number;
  IsRetired: boolean;
  isSelected: boolean;
  MasterCategory: string;
  ProductDesc: string;
  ProductId: number;
  ProductNo: string;
  Vendor: string;
  VendorId?: number;
  Weight: number;
};

export type TagRestriction = {
  isSelected: boolean;
  TagId: number;
  TagName: string;
};

export type TierRestriction = {
  ChargeCodeDesc: string;
  ChargeCodeGroupName: string;
  ChargeCodeId: number;
  ChargeCodeName: string;
  IsExactWeightBuindle: boolean;
  isSelected: boolean;
  PriceId?: number;
  PriceTierGroupId?: number;
  PricingTierPerProduct: boolean;
};

export enum CalculationMethods {
  AmountOff = 1,
  PercentOff = 2,
  PriceToAmount = 3,
  PriceToCostPlusPercent = 4,
  AmountOffTotal = 5,
  PriceToAmountTotal = 6,
}

export enum ManualDefaultApplyTo {
  JustOneItem = 1,
  EachItemInRow = 2,
  EveryItemInCart = 3,
  DistributedAmongEveryItem = 4,
}

export enum ApplicationMethods {
  Automatically = 1,
  Manually = 2,
  ByCode = 3,
  BySpringBig = 4,
  ByAlpineIQ = 5,
  ByFyllo = 6,
}

export const ApplicationMethodsString = [
  { key: ApplicationMethods.Automatically, val: 'Automatic' },
  { key: ApplicationMethods.Manually, val: 'Manual' },
  { key: ApplicationMethods.ByCode, val: 'Code' },
  { key: ApplicationMethods.BySpringBig, val: 'SpringBig' },
  { key: ApplicationMethods.ByAlpineIQ, val: 'AlpineIQ' },
];

export enum ItemGroupType {
  All = 5,
  BySKU = 6,
}

export enum HighestOrLowest {
  high = 'high',
  low = 'low',
}

export type discountDropDownOption = {
  autoOrCodeOnly?: boolean;
  name: string;
  val: number;
};

export const firstTimeCustomerOptions = [
  { name: 'No', val: DiscountFirstTimeCustomer.NoFirstTimeDiscount },
  { name: 'Per location', val: DiscountFirstTimeCustomer.ByLocation },
  { name: 'All locations', val: DiscountFirstTimeCustomer.AllLocations },
];

export const thresholdTypes = [
  { name: 'No threshold', val: ThresholdType.NoThreshold },
  { name: 'Total items', val: ThresholdType.NumberOfItems },
  { name: 'Total spend', val: ThresholdType.SpendingAmount },
  { name: 'Total weight', val: ThresholdType.ProductWeight },
];

export const constraintThresholdTypes = [
  { name: 'Total items', val: ThresholdType.NumberOfItems },
  { name: 'Total spend', val: ThresholdType.SpendingAmount },
  { name: 'Total weight', val: ThresholdType.ProductWeight },
];

export const calculationMethodOptions: discountDropDownOption[] = [
  { name: 'Amount off - each item', val: CalculationMethods.AmountOff },
  { name: 'Amount off - total amount', val: CalculationMethods.AmountOffTotal },
  { name: 'Percent off', val: CalculationMethods.PercentOff },
  { name: 'Price to amount - each item', val: CalculationMethods.PriceToAmount },
  {
    name: 'Price to amount - total amount',
    val: CalculationMethods.PriceToAmountTotal,
  },
  {
    name: 'Price to cost plus percent',
    val: CalculationMethods.PriceToCostPlusPercent,
  },
];

export const manualDiscountCalculationOptions: discountDropDownOption[] = [
  { name: 'Amount off', val: CalculationMethods.AmountOff },
  { name: 'Percent off', val: CalculationMethods.PercentOff },
  { name: 'Price to amount', val: CalculationMethods.PriceToAmount },
  {
    name: 'Price to cost plus percent',
    val: CalculationMethods.PriceToCostPlusPercent,
  },
];

export const manualApplicationMethods = [
  ApplicationMethods.Manually,
  ApplicationMethods.BySpringBig,
  ApplicationMethods.ByAlpineIQ,
  ApplicationMethods.ByFyllo,
];

export const manualApplicationMethodsWithoutLoyalty = [ApplicationMethods.Manually];

export const manualDefaultApplyToMethods = [
  { name: 'Just one item', val: ManualDefaultApplyTo.JustOneItem },
  { name: 'Each item in row', val: ManualDefaultApplyTo.EachItemInRow },
  { name: 'Every item in cart', val: ManualDefaultApplyTo.EveryItemInCart },
  { name: 'Distributed among every item', val: ManualDefaultApplyTo.DistributedAmongEveryItem },
];

export const externalApplicationMethods = [
  ApplicationMethods.ByAlpineIQ,
  ApplicationMethods.BySpringBig,
  ApplicationMethods.ByFyllo,
];

export const appMethodsWithThresholds = [ApplicationMethods.Automatically, ApplicationMethods.ByCode];

export const applicationMethodsWithThresholdsWithLoyalty = [
  ApplicationMethods.Automatically,
  ApplicationMethods.ByCode,
  ApplicationMethods.ByAlpineIQ,
  ApplicationMethods.BySpringBig,
  ApplicationMethods.ByFyllo,
];
