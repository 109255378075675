import { useQuery } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { segmentDiscountKeys } from './query-key-factory';

export type SegmentDiscount = {
  DiscountName: string;
  ValidDateFrom: string;
  ValidDateTo: string;
};

type SegmentPayload = {
  segmentId: number | null;
};

export const GET_SEGMENT_DISCOUNTS = 'api/segment/get-segment-discounts';

export function useGetSegmentDiscountsQuery({ segmentId }: SegmentPayload) {
  return useQuery({
    queryKey: segmentDiscountKeys.one(Number(segmentId)),
    queryFn: () =>
      postData<SegmentPayload, SegmentDiscount[]>({ endpoint: GET_SEGMENT_DISCOUNTS, payload: { segmentId } }),
  });
}
