import React, { useMemo } from 'react';

import { gridNumberComparator } from '@mui/x-data-grid-pro';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';

import { endPoints as PosEndPoints } from 'src/app_deprecated/constants/PosConstants';
import HttpClient from 'src/app_deprecated/utils/HttpClient';

import { BlueProgressBadge, GreenFilledCheckBadge, RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { InputAdornment } from 'src/app/components/lib/input';
import { multiplyFloats } from 'src/app/utils/accurate-arithmetic';

import {
  ApplicationMethods,
  applicationMethodsWithThresholdsWithLoyalty,
  appMethodsWithThresholds,
  calculationMethodOptions,
  CalculationMethods,
  HighestOrLowest,
  ItemGroupType,
  ManualDefaultApplyTo,
  manualDiscountCalculationOptions,
  ThresholdType,
} from './discount-models';
import { clearRewardRestrictions } from './restrictions/discount-restrict-helpers';

import type { Discount, discountDropDownOption as DiscountDropDownOption } from './discount-models';
import type { GridComparatorFn, GridRowModel, GridSortCellParams } from '@mui/x-data-grid-pro';
import type { Moment } from 'moment';

export function displayAdornment(discount: Discount) {
  if (
    discount.Reward.CalculationMethodId !== CalculationMethods.PercentOff &&
    discount.Reward.CalculationMethodId !== CalculationMethods.PriceToCostPlusPercent
  ) {
    return <InputAdornment position='start'>$</InputAdornment>;
  }
  return false;
}

export function useGetApplicationMethods(useSpringBig: boolean, useAlpineIq: boolean, useFyllo: boolean) {
  return useMemo(() => getApplicationMethods(useSpringBig, useAlpineIq, useFyllo), []);
}

function getApplicationMethods(useSpringBig: boolean, useAlpineIq: boolean, useFyllo: boolean) {
  if (useSpringBig && !_.some(applicationMethods, (method) => method.name == i18next.t('By SpringBig'))) {
    applicationMethods.push({ name: i18next.t('By SpringBig'), val: ApplicationMethods.BySpringBig });
  }
  if (useAlpineIq && !_.some(applicationMethods, (method) => method.name == i18next.t('By Alpine IQ'))) {
    applicationMethods.push({ name: i18next.t('By Alpine IQ'), val: ApplicationMethods.ByAlpineIQ });
  }
  if (useFyllo && !_.some(applicationMethods, (method) => method.name == i18next.t('By Fyllo'))) {
    applicationMethods.push({ name: i18next.t('By Fyllo'), val: ApplicationMethods.ByFyllo });
  }

  return applicationMethods;
}

const applicationMethods = [
  { name: i18next.t('Automatic'), val: ApplicationMethods.Automatically },
  { name: i18next.t('Manual'), val: ApplicationMethods.Manually },
  { name: i18next.t('Code'), val: ApplicationMethods.ByCode },
];

// discount getter/setter
export const fetchDiscount = async (
  discountIdToGet: number,
  setDiscount: (Discount) => void,
  setLoading: (boolean) => void
) => {
  setLoading(true);
  try {
    const response: Discount = await HttpClient.post(
      PosEndPoints.GET_DISCOUNT_BY_ID,
      {
        DiscountId: discountIdToGet,
      },
      'Failed to load discount'
    );

    response.ValidDateFrom = moment(response.ValidDateFrom).format('MM/DD/YYYY hh:mm A') || '';
    response.ValidDateTo = moment(response.ValidDateTo).format('MM/DD/YYYY hh:mm A') || '';
    response.StartTime = response.StartTime ? moment(response.StartTime, 'LT').format('h:mm a') : undefined;
    response.EndTime = response.EndTime ? moment(response.EndTime, 'LT').format('h:mm a') : undefined;
    response.Reward.DiscountValue = [CalculationMethods.PercentOff, CalculationMethods.PriceToCostPlusPercent].includes(
      response.Reward.CalculationMethodId
    )
      ? multiplyFloats(response.Reward.DiscountValue, 100)
      : response.Reward.DiscountValue;

    // Manual discounts cannot have constraints, reduntantly enforce that here
    if (ApplicationMethods.Manually === response.ApplicationMethodId) {
      response.Constraints = [];
    }

    setDiscount(response);
  } finally {
    setLoading(false);
  }
};

type UpdateDiscountParams = {
  discount: Discount;
  savedWithAdvancedOptions: boolean;
  setDiscount: (discount: Discount) => void;
  setLoading: (loading: boolean) => void;
};

export const updateDiscount = async ({
  discount,
  setDiscount,
  setLoading,
  savedWithAdvancedOptions,
}: UpdateDiscountParams): Promise<void> => {
  setLoading(true);
  const toSave = _.cloneDeep(discount);

  toSave.LocationRestrictions = toSave.LocationRestrictions ? toSave.LocationRestrictions : [];
  toSave.RestrictToGroupIds = toSave.RestrictToGroupIds ? toSave.RestrictToGroupIds : [];
  toSave.RestrictToSegmentIds = toSave.RestrictToSegmentIds ? toSave.RestrictToSegmentIds : [];
  toSave.ValidDateFrom = moment(discount.ValidDateFrom).local().format('MM/DD/YYYY h:mm a');
  toSave.ValidDateTo = moment(discount.ValidDateTo).local().format('MM/DD/YYYY h:mm a');
  toSave.Reward.DiscountValue = [CalculationMethods.PercentOff, CalculationMethods.PriceToCostPlusPercent].includes(
    toSave.Reward.CalculationMethodId
  )
    ? toSave.Reward.DiscountValue / 100
    : toSave.Reward.DiscountValue;

  if (discount.IsBundledDiscount) {
    clearRewardRestrictions(toSave);
  }

  toSave.SavedWithAdvancedOptions = savedWithAdvancedOptions;
  const createdId = await HttpClient.post(
    PosEndPoints.SAVE_DISCOUNT,
    { Discount: toSave },
    'Failed to update Discount',
    'Discount updated successfully'
  );
  if (createdId) {
    await fetchDiscount(createdId, setDiscount, setLoading);
  }
  setLoading(false);
};

export const menuDisplayRankSortComparator: GridComparatorFn<string> = (
  v1,
  v2,
  param1: GridSortCellParams<string>,
  param2: GridSortCellParams<string>
) => {
  let val1 = parseInt(v1, 10);
  let val2 = parseInt(v2, 10);
  if (_.isNaN(val1)) {
    val1 = -1;
  }
  if (_.isNaN(val2)) {
    val2 = -1;
  }
  return gridNumberComparator(val1, val2, param1, param2);
};

export const calculateMaxMenuRank = (ecommDiscounts: GridRowModel[]) => {
  const result = ecommDiscounts.reduce(
    (a: GridRowModel, b: GridRowModel) => ({
      MenuDisplayRank: Math.max(a.MenuDisplayRank ?? -1, b.MenuDisplayRank ?? -1),
    }),
    { MenuDisplayRank: -Infinity }
  );
  let maxRank = result.MenuDisplayRank as number;
  if (Number.isNaN(maxRank) || maxRank === -Infinity) {
    maxRank = -1; // If all ranks are null, set max to -1 so when we increment it when populating ranks, it becomes 0
  }
  return maxRank;
};

export const saveMenuRanksToServer = async (discountRanks, errorMessage, successMessage) =>
  HttpClient.post(
    PosEndPoints.SAVE_DISCOUNTS_MENU_RANKS,
    { DiscountIdsToRanks: discountRanks },
    errorMessage || undefined,
    successMessage || undefined,
    false
  );

export const updateDiscountMenuRanks = ({ setRanks, emitMessages = true }): void => {
  setRanks(async (discountIdAndRankDict: Record<string, number>) => {
    const objEntries = Object.entries(discountIdAndRankDict);
    if (objEntries.length === 0) {
      return discountIdAndRankDict;
    }
    const discountRanks = objEntries.map((entry) => ({ DiscountId: entry[0], Rank: entry[1] }));
    try {
      await saveMenuRanksToServer(
        discountRanks,
        emitMessages ? 'Reordering discounts failed! Please reload the page and try again.' : undefined,
        emitMessages ? 'Discounts successfully reordered. It may take a few minutes to see these changes.' : undefined
      );
      // Success, reset the ranks that need to be updated
      return {};
    } catch {
      // Failure, reset ranks to initial value so another update is triggered
      return discountIdAndRankDict;
    }
  });
};

// Discount.Reward.ThresholdTypeId change handler
export const changeThresholdType = (discount, thresholdTypeId, setDiscount) => {
  const newThresholdId = parseInt(thresholdTypeId, 10);

  let reward = discount.Reward;
  if (!(newThresholdId > 0)) {
    reward = {
      ...reward,
      HasThreshold: false,
      ThresholdTypeId: newThresholdId,
      ThresholdMin: undefined,
      ThresholdMax: undefined,
      ApplyToOnlyOneItem: false,
      ItemGroupTypeId: ItemGroupType.All,
      HighestOrLowest: HighestOrLowest.low,
    };
  } else if (newThresholdId !== ThresholdType.NumberOfItems) {
    reward = {
      ...reward,
      HasThreshold: true,
      ThresholdTypeId: newThresholdId,
      ThresholdMax: undefined,
    };
  } else {
    reward = {
      ...reward,
      HasThreshold: true,
      ThresholdTypeId: newThresholdId,
    };
  }

  setDiscount({
    ...discount,
    Reward: reward,
  });
};

// Discount.ApplicationMethod change handler
export const changeApplicationMethod = (discount, applicationMethodId, setDiscount: (Discount) => void) => {
  const newMethodId = parseInt(applicationMethodId, 10);
  let newCalcId = discount.Reward.CalculationMethodId;

  if (
    (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmountTotal ||
      discount.Reward.CalculationMethodId === CalculationMethods.AmountOffTotal) &&
    newMethodId !== ApplicationMethods.Automatically
  ) {
    newCalcId = CalculationMethods.AmountOff;
  }

  switch (newMethodId) {
    case ApplicationMethods.Manually:
      resetThresholdOptions(discount, setDiscount, newMethodId, newCalcId);
      break;
    case ApplicationMethods.Automatically:
      setDiscount({
        ...discount,
        ApplicationMethodId: newMethodId,
        CalculationMethodId: newCalcId,
        LoyaltyPointValue: null,
        ExternalId: '',
        RedemptionLimit: '',
      });
      break;
    case ApplicationMethods.ByCode:
      setDiscount({
        ...discount,
        ApplicationMethodId: newMethodId,
        CalculationMethodId: newCalcId,
        CanStackAutomatically: false,
        IsBundledDiscount: false,
        LoyaltyPointValue: null,
        ExternalId: '',
        PaymentRestrictions: null,
      });
      break;
    case ApplicationMethods.BySpringBig:
      resetThresholdOptions(discount, setDiscount, newMethodId, newCalcId);
      break;
    case ApplicationMethods.ByAlpineIQ:
      resetThresholdOptions(discount, setDiscount, newMethodId, newCalcId);
      break;
    case ApplicationMethods.ByFyllo:
      resetThresholdOptions(discount, setDiscount, newMethodId, newCalcId);
      break;
  }
};

const resetThresholdOptions = (discount, setDiscount, newMethodId, newCalcId) => {
  setDiscount({
    ...discount,
    ApplicationMethodId: newMethodId,
    IsBundledDiscount: false,
    Constraints: [],
    CanStackAutomatically: false,
    ExternalId: '',
    RedemptionLimit: '',
    Reward: {
      ...discount.Reward,
      HasThreshold: false,
      ThresholdTypeId: undefined,
      ThresholdMin: undefined,
      ThresholdMax: undefined,
      CalculationMethodId: newCalcId,
      ApplyToOnlyOneItem: false,
      ItemGroupTypeId: ItemGroupType.All,
    },
    PaymentRestrictions: null,
  });
};

// label getters
export const getDiscountValueLabel = (discount: Discount): JSX.Element | string => {
  if (discount.Reward.CalculationMethodId === CalculationMethods.PercentOff) {
    return 'Percent off:';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToCostPlusPercent) {
    return 'Percent off:';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmount) {
    return (
      <span>
        Price to <br />
        amount:
      </span>
    );
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmountTotal) {
    return (
      <span>
        Price to <br />
        amount:
      </span>
    );
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.AmountOffTotal) {
    return 'Amount off:';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.AmountOff) {
    return 'Amount off:';
  }
  return (
    <span>
      Discount <br />
      amount:
    </span>
  );
};

export const getDiscountMethodOptions = (discount: Discount, loyaltyAsCode?: boolean): DiscountDropDownOption[] => {
  // @TODO Cleanup with removal of pos.core.cats.discounts.single-redemption-manual-loyalty.rollout
  const applicationMethod = discount?.ApplicationMethodId ?? ApplicationMethods.Manually;
  if (
    (loyaltyAsCode && applicationMethodsWithThresholdsWithLoyalty.includes(applicationMethod)) ||
    appMethodsWithThresholds.includes(applicationMethod)
  ) {
    return calculationMethodOptions;
  }

  return manualDiscountCalculationOptions;
};

export const getDiscountApplyToValue = (discount: Discount): ManualDefaultApplyTo => {
  if (
    discount.Reward.CalculationMethodId === CalculationMethods.PercentOff &&
    discount.Reward.ManualDefaultApplyTo === Number(ManualDefaultApplyTo.EveryItemInCart)
  ) {
    return ManualDefaultApplyTo.DistributedAmongEveryItem;
  }
  return discount.Reward.ManualDefaultApplyTo;
};

export const getDiscountMethodValue = (discount: Discount, hasTotals: boolean): string => {
  if (
    discount.ApplicationMethodId === ApplicationMethods.Automatically ||
    discount.ApplicationMethodId === ApplicationMethods.ByCode
  ) {
    return String(discount.Reward.CalculationMethodId > 0 ? discount.Reward.CalculationMethodId : '');
  }

  if (discount.Reward?.CalculationMethodId === CalculationMethods.AmountOffTotal && !hasTotals) {
    return String(CalculationMethods.AmountOff);
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmountTotal && !hasTotals) {
    return String(CalculationMethods.PriceToAmount);
  }
  return String(discount.Reward.CalculationMethodId > 0 ? discount.Reward.CalculationMethodId : '');
};

export const getDiscountMethodTooltip = (discount: Discount): string => {
  if (discount.Reward.CalculationMethodId === CalculationMethods.PercentOff) {
    return 'Percent off: Discount by a percent of the price.';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.AmountOff) {
    return 'Amount off - each item: Discount each item by a fixed amount.';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.AmountOffTotal) {
    return 'Amount off - total: Discount the total price by a fixed amount.';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmountTotal) {
    return 'Price to amount - total: Discount the total price to a static amount.';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToAmount) {
    return 'Price to amount - each item: Discount the price of each item to a static amount.';
  }
  if (discount.Reward.CalculationMethodId === CalculationMethods.PriceToCostPlusPercent) {
    return 'Price to cost plus percent: Discount price to cost of item plus a percentage.';
  }
  return '';
};

export const getExternalIdName = (discount: Discount): string => {
  switch (discount.ApplicationMethodId) {
    case ApplicationMethods.BySpringBig:
      return 'Discount ID:'; // @todo update this?
    case ApplicationMethods.ByAlpineIQ:
      return 'Alpine IQ discount ID:';
    case ApplicationMethods.ByFyllo:
      return 'Fyllo discount ID:';
    default:
      return 'External ID:';
  }
};

export const getThresholdTooltip = (discount: Discount): string => {
  switch (discount.Reward.ThresholdTypeId) {
    case ThresholdType.NumberOfItems:
      return discount.Reward.ApplyToOnlyOneItem
        ? 'The total size of the discount group required to earn the reward. Example: Buy 1, get 1 = 2. Buy 2, get 1 = 3.'
        : 'The number of reward items that must be purchased to earn this discount.';
    case ThresholdType.SpendingAmount:
      return 'Determines how much must be spent in order for the discount to apply.';
    case ThresholdType.ProductWeight:
      return 'Determines how many grams of product must be purchased in order for the discount to apply.';
    default:
      return 'The number of reward items that must be purchased to earn this discount.';
  }
};

export const getThresholdTooltipConstraint = (id: number): string => {
  switch (id) {
    case ThresholdType.NumberOfItems:
      return 'The number of constraint items that must be purchased to earn this discount.';
    case ThresholdType.SpendingAmount:
      return 'Determines how much must be spent in order for the discount to apply.';
    case ThresholdType.ProductWeight:
      return 'Determines how many grams of product must be purchased in order for the discount to apply.';
    default:
      return 'The number of constraint items that must be purchased to earn this discount.';
  }
};

export const getMinItemsLabel = (discount: Discount): JSX.Element | string => {
  switch (discount.Reward.ThresholdTypeId) {
    case ThresholdType.NumberOfItems:
      return discount.Reward.ApplyToOnlyOneItem ? 'BOGO group size:' : 'Min items:';
    case ThresholdType.SpendingAmount:
      return (
        <span>
          Dollar <br /> amount:
        </span>
      );
    case ThresholdType.ProductWeight:
      return 'Threshold weight:';
    default:
      return 'Min items:';
  }
};

export const getMinItemsLabelConstraint = (id: number): JSX.Element => {
  switch (id) {
    case ThresholdType.NumberOfItems:
      return (
        <span>
          Min <br />
          items:
        </span>
      );
    case ThresholdType.SpendingAmount:
      return (
        <span>
          Dollar <br /> amount:
        </span>
      );
    case ThresholdType.ProductWeight:
      return (
        <span>
          Min <br /> grams:
        </span>
      );
    default:
      return <span>Threshold minimum:</span>;
  }
};

export const formatLabel = (text: string, numRestricted = 0): string =>
  numRestricted ? `${text} (${numRestricted})` : text;

export const applyPercentOffFiltersAndRenames = (
  discount: Discount,
  manualDefaultApplyToMethods: {
    name: string;
    val: ManualDefaultApplyTo;
  }[]
) => {
  // We replace 'Distributed among every item' and 'Every item in the cart' option for the Apply to subtotal options with 'Apply to subtotal' since
  const isPercentOff = discount.Reward.CalculationMethodId === CalculationMethods.PercentOff;

  // This hides the 'Every item in the cart' option for the Apply to subtotal options when the discount is percent off
  const percentOffFilters = (value: { name: string; val: ManualDefaultApplyTo }) =>
    (value.name !== manualDefaultApplyToMethods[2].name && isPercentOff) ||
    // Show all options when not percent off or when not applying percent off filters
    !isPercentOff;

  // This renames the 'Distributed among every item' option for the Apply to subtotal options to 'Apply to subtotal' when the discount is percent off
  return manualDefaultApplyToMethods.filter(percentOffFilters).map((value) => ({
    ...value,
    name: value.name === manualDefaultApplyToMethods[3].name && isPercentOff ? 'Apply to subtotal' : value.name,
  }));
};

enum DiscountValidity {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  UPCOMING = 'Upcoming',
}

type DiscountValidityText = `${DiscountValidity}`;

enum DiscountValiditySort {
  ACTIVE = 1,
  UPCOMING = 2,
  EXPIRED = 3,
}

type DiscountValiditySortText = `${DiscountValiditySort}`;

type DiscountValidityProps = {
  validFrom: Moment;
  validTo: Moment;
};

export const getDiscountValidity = ({ validFrom, validTo }: DiscountValidityProps): DiscountValidity => {
  const now = moment(new Date());
  // eslint-disable-next-line no-nested-ternary
  return validFrom.isAfter(now)
    ? DiscountValidity.UPCOMING // future validFrom is UPCOMING
    : validTo.isAfter(now)
    ? DiscountValidity.ACTIVE // past validFrom and future validTo is ACTIVE
    : DiscountValidity.EXPIRED; // past validFrom and past validTo is EXPIRED
};

export const getDiscountValidityText = (discountValidity: DiscountValidity): DiscountValidityText =>
  `${discountValidity}`;

export const getDiscountValiditySortText = (discountValidity: DiscountValidity): DiscountValiditySortText => {
  switch (discountValidity) {
    case DiscountValidity.ACTIVE:
      return `${DiscountValiditySort.ACTIVE}`;
    case DiscountValidity.UPCOMING:
      return `${DiscountValiditySort.UPCOMING}`;
    case DiscountValidity.EXPIRED:
    default:
      return `${DiscountValiditySort.EXPIRED}`;
  }
};

export const discountValidityToStatusElement = (validity: DiscountValidity): JSX.Element => {
  const validityText = getDiscountValidityText(validity);
  switch (validity) {
    case DiscountValidity.ACTIVE:
      return <GreenFilledCheckBadge label={validityText} />;
    case DiscountValidity.UPCOMING:
      return <BlueProgressBadge label={validityText} />;
    case DiscountValidity.EXPIRED:
    default:
      return <RedErrorBadge label={validityText} />;
  }
};

export const apiDateToMoment = (apiDate: string): Moment => moment(apiDate, 'YYYY-MM-DDThh:mm:ss');
