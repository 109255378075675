/*
  JavaScript's implementation of floating-point arithmetic is broken, so these helpers
  have been written, so we don't have to worry about simple operations resulting in numbers
  with a huge number of decimal places. See below for source material and context.

  Sources:
  - https://randomascii.wordpress.com/2012/02/25/comparing-floating-point-numbers-2012-edition/
  - https://randomascii.wordpress.com/2020/09/27/floating-point-in-the-browser-part-1-impossible-expectations/
  - https://262.ecma-international.org/5.1/#sec-9.8.1
  - https://en.wikipedia.org/wiki/IEEE_754
  - https://stackoverflow.com/a/44949594
  - https://stackoverflow.com/a/5037927
  JavaScript arithmetic examples (in Node.js and the browser):
    Addition:
    > 39.55 + 11.3
    50.849999999999994
    > Math.round((39.55 + 11.3) * 1e12) / 1e12
    50.85

    Subtraction:
    > 39.55 - 11.3
    28.249999999999996
    > Math.round((39.55 - 11.3) * 1e12) / 1e12
    28.25


    Multiplication:
    > 11.3 * 3.50
    39.550000000000004
    > Math.round((39.55 * 11.3) * 1e12) / 1e12
    39.55

    Division:
    > 39.55 / 11.3
    3.4999999999999996
    > Math.round((39.55 / 11.3) * 1e12) / 1e12
    3.5
 */

/**
 * Multiply two floating-point numbers and round the result, avoiding a large inaccurate fraction as the result
 * @param {number} multiplicand The number being multiplied
 * @param {number} multiplier The number to multiply the multiplicand by
 * @returns {number} The rounded result of {@link multiplicand} * {@link multiplier}
 */
export const multiplyFloats = (multiplicand: number, multiplier: number): number =>
  Math.round(multiplicand * multiplier * 1e12) / 1e12;
/**
 * Divide two floating-point numbers and round the result, avoiding a large inaccurate fraction as the result
 * @param {number} dividend The number being divided
 * @param {number} divisor The number to divide the dividend by
 * @returns {number} The rounded result of {@link dividend} / {@link divisor}
 */
export const divideFloats = (dividend: number, divisor: number): number =>
  Math.round((dividend / divisor) * 1e12) / 1e12;
/**
 * Add two floating-point numbers, and round the result, avoiding a large inaccurate fraction as the result
 * @param {number} augend The number being added to
 * @param {number} addend The number to add to the augend
 * @returns {number} The rounded result of {@link augend} + {@link addend}
 */
export const addFloats = (augend: number, addend: number): number /*   */ =>
  Math.round((augend + addend) * 1e12) / 1e12;
/**
 * Subtract two floating-point numbers, and round the result, avoiding a large inaccurate fraction as the result
 * @param {number} minuend The number being subtracted from
 * @param {number} subtrahend The number to subtract from the minuend
 * @returns {number} The rounded result of {@link minuend} - {@link subtrahend}
 */
export const subtractFloats = (minuend: number, subtrahend: number): number =>
  Math.round((minuend - subtrahend) * 1e12) / 1e12;

/**
 * Round {num} to {fractionalDigits} fractional digits
 * @param {number} num The number to round
 * @param {number} fractionalDigits The number of fractional digits to round the number to
 * @returns {number} {@link num} rounded to {@link fractionalDigits} fractional digits
 */
export const roundNumber = (num: number, fractionalDigits: number): number =>
  parseFloat(numberToFixed(num, fractionalDigits));

/**
 * Round {num} to {fractionalDigits} fractional digits and convert it to a string
 * @param {number} num The number to round
 * @param {number} fractionalDigits The number of fractional digits to round the number to
 * @returns {string} {@link num} converted to a string with fixed {@link fractionalDigits} fractional digits
 */
export const numberToFixed = (num: number, fractionalDigits: number): string =>
  Number(`${num}`).toFixed(parseInt(`${fractionalDigits}`, 10));
