import _ from 'lodash';

import { ItemGroupType } from '../discount-models';

import type { Discount, Constraint, Reward, Restrictions, StrainRestriction } from '../discount-models';

// restriction helpers
export const clearRewardRestrictions = (toSave: Discount) => {
  const reward: Reward = {
    DiscountId: toSave.Id,
    DiscountRewardId: toSave.Reward.DiscountRewardId,
    HasThreshold: false,
    ApplyToOnlyOneItem: false,
    CalculationMethodId: toSave.Reward.CalculationMethodId,
    DiscountValue: toSave.Reward.DiscountValue,
    HighestOrLowest: 'low',
    IncludeNonCannabis: true,
    ItemGroupTypeId: ItemGroupType.All,
    ManualDefaultApplyTo: 1,
    Products: [],
    Restrictions: {
      ...toSave.Reward.Restrictions,
      Strain: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Category: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Vendor: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Brand: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Weight: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Product: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Tag: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      InventoryTag: {
        IsExclusion: false,
        RestrictionIds: [],
      },
      Tier: {
        IsExclusion: false,
        RestrictionIds: [],
      },
    },
  };

  toSave.Reward = reward;
};

export const sortRestriction = (restrictions: Restrictions, itemData): any => {
  const sortedItems = itemData.items
    .map((item) => {
      if (
        restrictions[itemData.name]?.RestrictionIds &&
        restrictions[itemData.name]?.RestrictionIds.some((restrictionId) => item[itemData.id] === restrictionId)
      ) {
        return { ...item, isSelected: true };
      }
      return { ...item, isSelected: false };
    })
    .sort((a, b) => {
      if (a.isSelected) {
        return -1;
      }
      if (b.isSelected) {
        return 1;
      }
      return 0;
    });
  return sortedItems;
};

export const getNumRestrictions = (discount: Discount) => ({
  strains: discount.Reward.Restrictions?.Strain?.RestrictionIds.length ?? 0,
  categories: discount.Reward.Restrictions?.Category?.RestrictionIds.length ?? 0,
  brands: discount.Reward.Restrictions?.Brand?.RestrictionIds.length ?? 0,
  vendors: discount.Reward.Restrictions?.Vendor?.RestrictionIds.length ?? 0,
  weights: discount.Reward.Restrictions?.Weight?.RestrictionIds.length ?? 0,
  tags: discount.Reward.Restrictions?.Tag?.RestrictionIds.length ?? 0,
  inventorytags: discount.Reward.Restrictions?.InventoryTag?.RestrictionIds.length ?? 0,
  tiers: discount.Reward.Restrictions?.Tier?.RestrictionIds.length ?? 0,
  products: discount.Reward.Restrictions?.Product?.RestrictionIds.length ?? 0,
});

export const getConstraintNumRestrictions = (constraint: Constraint) => ({
  strains: constraint.Restrictions?.Strain?.RestrictionIds.length ?? 0,
  categories: constraint.Restrictions?.Category?.RestrictionIds.length ?? 0,
  brands: constraint.Restrictions?.Brand?.RestrictionIds.length ?? 0,
  vendors: constraint.Restrictions?.Vendor?.RestrictionIds.length ?? 0,
  weights: constraint.Restrictions?.Weight?.RestrictionIds.length ?? 0,
  tags: constraint.Restrictions?.Tag?.RestrictionIds.length ?? 0,
  inventorytags: constraint.Restrictions?.InventoryTag?.RestrictionIds.length ?? 0,
  tiers: constraint.Restrictions?.Tier?.RestrictionIds.length ?? 0,
  products: constraint.Restrictions?.Product?.RestrictionIds.length ?? 0,
});

export const getRewardRestrictionSummary = (discount: Discount, p: any[], filterProducts = true) => {
  let summary = _.cloneDeep(p);
  summary.forEach((x) => (x.isSelected = false));
  if (discount.Reward.Restrictions?.Strain?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Strain?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Strain?.RestrictionIds.includes(product.StrainId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Strain?.RestrictionIds.includes(product.StrainId)
      );
    }
  }

  if (discount.Reward.Restrictions?.Category?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Category?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Category?.RestrictionIds.includes(product.CategoryId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Category?.RestrictionIds.includes(product.CategoryId)
      );
    }
  }

  if (discount.Reward.Restrictions?.Brand?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Brand?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Brand?.RestrictionIds.includes(product.BrandId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Brand?.RestrictionIds.includes(product.BrandId)
      );
    }
  }

  if (discount.Reward.Restrictions?.Vendor?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Vendor?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Vendor?.RestrictionIds.includes(product.VendorId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Vendor?.RestrictionIds.includes(product.VendorId)
      );
    }
  }

  if (discount.Reward.Restrictions?.Weight?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Weight?.IsExclusion) {
      summary = summary.filter(
        (product) =>
          !discount.Reward.Restrictions?.Weight?.RestrictionIds.includes(
            product.Weight ?? (product.UnitTypeName === 'Quantity' ? 0 : undefined)
          )
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Weight?.RestrictionIds.includes(
          product.Weight ?? (product.UnitTypeName === 'Quantity' ? 0 : undefined)
        )
      );
    }
  }

  if (discount.Reward.Restrictions?.Tag?.RestrictionIds?.length > 0) {
    const restrictedTags = discount.Reward.Restrictions?.Tag?.RestrictionIds;
    if (discount.Reward.Restrictions?.Tag?.IsExclusion) {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.TagIds?.includes(y));
        return !(selectedTagsToProducts?.length > 0);
      });
    } else {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.TagIds?.includes(y));
        return selectedTagsToProducts?.length > 0;
      });
    }
  }

  if (discount.Reward.Restrictions?.InventoryTag?.RestrictionIds?.length > 0) {
    const restrictedTags = discount.Reward.Restrictions?.InventoryTag?.RestrictionIds;
    if (discount.Reward.Restrictions?.InventoryTag?.IsExclusion) {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.InventoryTagIds?.includes(y));
        return !(selectedTagsToProducts?.length > 0);
      });
    } else {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.InventoryTagIds?.includes(y));
        return selectedTagsToProducts?.length > 0;
      });
    }
  }

  if (discount.Reward.Restrictions?.Tier?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Tier?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Tier?.RestrictionIds.includes(product.ChargeCodeId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Tier?.RestrictionIds.includes(product.ChargeCodeId)
      );
    }
  }

  if (filterProducts && discount.Reward.Restrictions?.Product?.RestrictionIds?.length > 0) {
    if (discount.Reward.Restrictions?.Product?.IsExclusion) {
      summary = summary.filter(
        (product) => !discount.Reward.Restrictions?.Product?.RestrictionIds.includes(product.ProductId)
      );
    } else {
      summary = summary.filter((product) =>
        discount.Reward.Restrictions?.Product?.RestrictionIds.includes(product.ProductId)
      );
    }
  }

  return summary;
};

export const getConstraintRestrictionSummary = (constraint: Constraint, p: any[], filterProducts = true) => {
  let summary = _.cloneDeep(p);
  summary.forEach((x) => {
    x.isSelected = false;
  });

  if (constraint.Restrictions?.Strain?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Strain?.IsExclusion) {
      summary = summary.filter(
        (product) => !constraint.Restrictions?.Strain?.RestrictionIds.includes(product.StrainId)
      );
    } else {
      summary = summary.filter((product) => constraint.Restrictions?.Strain?.RestrictionIds.includes(product.StrainId));
    }
  }

  if (constraint.Restrictions?.Category?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Category?.IsExclusion) {
      summary = summary.filter(
        (product) => !constraint.Restrictions?.Category?.RestrictionIds.includes(product.CategoryId)
      );
    } else {
      summary = summary.filter((product) =>
        constraint.Restrictions?.Category?.RestrictionIds.includes(product.CategoryId)
      );
    }
  }

  if (constraint.Restrictions?.Brand?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Brand?.IsExclusion) {
      summary = summary.filter((product) => !constraint.Restrictions?.Brand?.RestrictionIds.includes(product.BrandId));
    } else {
      summary = summary.filter((product) => constraint.Restrictions?.Brand?.RestrictionIds.includes(product.BrandId));
    }
  }

  if (constraint.Restrictions?.Vendor?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Vendor?.IsExclusion) {
      summary = summary.filter(
        (product) => !constraint.Restrictions?.Vendor?.RestrictionIds.includes(product.VendorId)
      );
    } else {
      summary = summary.filter((product) => constraint.Restrictions?.Vendor?.RestrictionIds.includes(product.VendorId));
    }
  }

  if (constraint.Restrictions?.Weight?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Weight?.IsExclusion) {
      summary = summary.filter(
        (product) =>
          !constraint.Restrictions?.Weight?.RestrictionIds.includes(
            product.Weight ?? (product.UnitTypeName === 'Quantity' ? 0 : undefined)
          )
      );
    } else {
      summary = summary.filter((product) =>
        constraint.Restrictions?.Weight?.RestrictionIds.includes(
          product.Weight ?? (product.UnitTypeName === 'Quantity' ? 0 : undefined)
        )
      );
    }
  }

  if (constraint.Restrictions?.Tag?.RestrictionIds?.length > 0) {
    const restrictedTags = constraint.Restrictions?.Tag?.RestrictionIds;
    if (constraint.Restrictions?.Tag?.IsExclusion) {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.TagIds?.includes(y));
        return !(selectedTagsToProducts?.length > 0);
      });
    } else {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.TagIds?.includes(y));
        return selectedTagsToProducts?.length > 0;
      });
    }
  }

  if (constraint.Restrictions?.InventoryTag?.RestrictionIds?.length > 0) {
    const restrictedTags = constraint.Restrictions?.InventoryTag?.RestrictionIds;
    if (constraint.Restrictions?.InventoryTag?.IsExclusion) {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.InventoryTagIds?.includes(y));
        return !(selectedTagsToProducts?.length > 0);
      });
    } else {
      summary = summary.filter((x) => {
        const selectedTagsToProducts = restrictedTags?.filter((y) => x.InventoryTagIds?.includes(y));
        return selectedTagsToProducts?.length > 0;
      });
    }
  }

  if (constraint.Restrictions?.Tier?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Tier?.IsExclusion) {
      summary = summary.filter(
        (product) => !constraint.Restrictions?.Tier?.RestrictionIds.includes(product.ChargeCodeId)
      );
    } else {
      summary = summary.filter((product) =>
        constraint.Restrictions?.Tier?.RestrictionIds.includes(product.ChargeCodeId)
      );
    }
  }

  if (filterProducts && constraint.Restrictions?.Product?.RestrictionIds?.length > 0) {
    if (constraint.Restrictions?.Product?.IsExclusion) {
      summary = summary.filter(
        (product) => !constraint.Restrictions?.Product?.RestrictionIds.includes(product.ProductId)
      );
    } else {
      summary = summary.filter((product) =>
        constraint.Restrictions?.Product?.RestrictionIds.includes(product.ProductId)
      );
    }
  }

  return summary;
};

export const getSelectedRestrictions = (
  restrictableObjects: any[],
  restrictionType: string,
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  switch (restrictionType) {
    case 'Strain':
      return getStrainRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Category':
      return getCategoryRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Brand':
      return getBrandRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Vendor':
      return getVendorRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Weight':
      return getWeightRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Tag':
      return getTagRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'InventoryTag':
      return getInventoryTagRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Tier':
      return getPricingTierRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    case 'Product':
      return getProductRestrictions(restrictableObjects, discount, isConstraint, constraintIndex);
    default:
      return [];
  }
};

const getStrainRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): StrainRestriction[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((strain: StrainRestriction) => {
      if (
        constraint.Restrictions?.Strain?.RestrictionIds &&
        constraint.Restrictions?.Strain?.RestrictionIds.some((restrictionId) => strain.StrainId === restrictionId)
      ) {
        return { ...strain, isSelected: true };
      }
      return { ...strain, isSelected: false };
    });
  }
  return restrictableObjects.map((strain: StrainRestriction) => {
    if (
      discount.Reward.Restrictions?.Strain?.RestrictionIds &&
      discount.Reward.Restrictions?.Strain?.RestrictionIds.some((restrictionId) => strain.StrainId === restrictionId)
    ) {
      return { ...strain, isSelected: true };
    }
    return { ...strain, isSelected: false };
  });
};

const getCategoryRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((category) => {
      if (
        constraint.Restrictions?.Category?.RestrictionIds &&
        constraint.Restrictions?.Category?.RestrictionIds.some(
          (restrictionId) => category.ProductCategoryId === restrictionId
        )
      ) {
        return { ...category, isSelected: true };
      }
      return { ...category, isSelected: false };
    });
  }
  return restrictableObjects.map((category) => {
    if (
      discount.Reward.Restrictions?.Category?.RestrictionIds &&
      discount.Reward.Restrictions?.Category?.RestrictionIds.some(
        (restrictionId) => category.ProductCategoryId === restrictionId
      )
    ) {
      return { ...category, isSelected: true };
    }
    return { ...category, isSelected: false };
  });
};

const getBrandRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((brand) => {
      if (
        constraint.Restrictions?.Brand?.RestrictionIds &&
        constraint.Restrictions?.Brand?.RestrictionIds.some((restrictionId) => brand.BrandId === restrictionId)
      ) {
        return { ...brand, isSelected: true };
      }
      return { ...brand, isSelected: false };
    });
  }
  return restrictableObjects.map((brand) => {
    if (
      discount.Reward.Restrictions?.Brand?.RestrictionIds &&
      discount.Reward.Restrictions?.Brand?.RestrictionIds.some((restrictionId) => brand.BrandId === restrictionId)
    ) {
      return { ...brand, isSelected: true };
    }
    return { ...brand, isSelected: false };
  });
};

const getVendorRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((vendor) => {
      if (constraint.Restrictions?.Vendor?.RestrictionIds?.some((restrictionId) => vendor.VendorId === restrictionId)) {
        return { ...vendor, isSelected: true };
      }
      return { ...vendor, isSelected: false };
    });
  }
  return restrictableObjects.map((vendor) => {
    if (
      discount.Reward.Restrictions?.Vendor?.RestrictionIds &&
      discount.Reward.Restrictions?.Vendor?.RestrictionIds.some((restrictionId) => vendor.VendorId === restrictionId)
    ) {
      return { ...vendor, isSelected: true };
    }
    return { ...vendor, isSelected: false };
  });
};

const getWeightRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((item) => {
      if (
        constraint.Restrictions?.Weight?.RestrictionIds &&
        constraint.Restrictions?.Weight?.RestrictionIds.some((restrictionId) => item.weight === restrictionId)
      ) {
        return { ...item, isSelected: true };
      }
      return { ...item, isSelected: false };
    });
  }
  return restrictableObjects.map((item) => {
    if (
      discount.Reward.Restrictions?.Weight?.RestrictionIds &&
      discount.Reward.Restrictions?.Weight?.RestrictionIds.some((restrictionId) => item.weight === restrictionId)
    ) {
      return { ...item, isSelected: true };
    }
    return { ...item, isSelected: false };
  });
};

const getTagRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((tag) => {
      if (constraint.Restrictions?.Tag?.RestrictionIds?.some((restrictionId) => tag.TagId === restrictionId)) {
        return { ...tag, isSelected: true };
      }
      return { ...tag, isSelected: false };
    });
  }
  return restrictableObjects.map((tag) => {
    if (discount.Reward.Restrictions?.Tag?.RestrictionIds.some((restrictionId) => tag.TagId === restrictionId)) {
      return { ...tag, isSelected: true };
    }
    return { ...tag, isSelected: false };
  });
};

const getInventoryTagRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((tag) => {
      if (constraint.Restrictions?.InventoryTag?.RestrictionIds?.some((restrictionId) => tag.TagId === restrictionId)) {
        return { ...tag, isSelected: true };
      }
      return { ...tag, isSelected: false };
    });
  }
  return restrictableObjects.map((tag) => {
    if (
      discount.Reward.Restrictions?.InventoryTag?.RestrictionIds.some((restrictionId) => tag.TagId === restrictionId)
    ) {
      return { ...tag, isSelected: true };
    }
    return { ...tag, isSelected: false };
  });
};

const getPricingTierRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return restrictableObjects.map((tier) => {
      if (constraint.Restrictions?.Tier?.RestrictionIds?.some((restrictionId) => tier.ChargeCodeId === restrictionId)) {
        return { ...tier, isSelected: true };
      }
      return { ...tier, isSelected: false };
    });
  }
  return restrictableObjects.map((tier) => {
    if (
      discount.Reward.Restrictions?.Tier?.RestrictionIds.some((restrictionId) => tier.ChargeCodeId === restrictionId)
    ) {
      return { ...tier, isSelected: true };
    }
    return { ...tier, isSelected: false };
  });
};

const getProductRestrictions = (
  restrictableObjects: any[],
  discount: Discount,
  isConstraint: boolean,
  constraintIndex?: number
): any[] => {
  if (isConstraint) {
    const constraints = _.cloneDeep(discount.Constraints);
    const constraint = constraints[constraintIndex];
    return getConstraintRestrictionSummary(constraint, restrictableObjects, false).map((product) => {
      if (
        constraint.Restrictions?.Product?.RestrictionIds &&
        constraint.Restrictions.Product.RestrictionIds.some((restrictionId) => product.ProductId === restrictionId)
      ) {
        return { ...product, isSelected: true };
      }
      return { ...product, isSelected: false };
    });
  }
  return getRewardRestrictionSummary(discount, restrictableObjects, false).map((product) => {
    if (
      discount.Reward.Restrictions?.Product?.RestrictionIds &&
      discount.Reward.Restrictions?.Product?.RestrictionIds.some((restrictionId) => product.ProductId === restrictionId)
    ) {
      return { ...product, isSelected: true };
    }
    return { ...product, isSelected: false };
  });
};

// Setting restriction to selected values
export const setSelection = (
  selectedIds: any[],
  restrictionType: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  switch (restrictionType) {
    case 'Strain':
      setStrainRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Category':
      setCategoryRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Brand':
      setBrandRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Vendor':
      setVendorRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Weight':
      setWeightRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Tag':
      setTagRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'InventoryTag':
      setInventoryTagRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Tier':
      setPricingTierRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Product':
      setProductRestrictions(selectedIds, discount, setDiscount, isConstraint, constraintIndex);
      break;
  }
};

const setStrainRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Strain?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Strain = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Strain = {
      IsExclusion: discount.Reward.Restrictions?.Strain?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setCategoryRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Category?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Category = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Category = {
      IsExclusion: discount.Reward.Restrictions?.Category?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setBrandRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Brand?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Brand = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Brand = {
      IsExclusion: discount.Reward.Restrictions?.Brand?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setVendorRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Vendor?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Vendor = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Vendor = {
      IsExclusion: discount.Reward.Restrictions?.Vendor?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setWeightRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Weight?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Weight = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Weight = {
      IsExclusion: discount.Reward.Restrictions?.Weight?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setTagRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newTagRestriction = {
      IsExclusion: constraint.Restrictions?.Tag?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Tag = newTagRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Tag = {
      IsExclusion: discount.Reward.Restrictions?.Tag?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setInventoryTagRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newTagRestriction = {
      IsExclusion: constraint.Restrictions?.InventoryTag?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.InventoryTag = newTagRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.InventoryTag = {
      IsExclusion: discount.Reward.Restrictions?.InventoryTag?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setPricingTierRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Tier?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Tier = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Tier = {
      IsExclusion: discount.Reward.Restrictions?.Tier?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
const setProductRestrictions = (
  selectedIds: any[],
  discount,
  setDiscount,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = {
      IsExclusion: constraint.Restrictions?.Product?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    newRestriction.Product = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    newRestriction.Product = {
      IsExclusion: discount.Reward.Restrictions?.Product?.IsExclusion,
      RestrictionIds: selectedIds,
    };
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

// Setting restriction as exclusion/inclusion
export const setExclusion = (
  isExclusion: string,
  restrictionType: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  switch (restrictionType) {
    case 'Strain':
      setStrainExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Category':
      setCategoryExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Brand':
      setBrandExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Vendor':
      setVendorExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Weight':
      setWeightExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Tag':
      setTagExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'InventoryTag':
      setInventoryTagExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Tier':
      setPricingTierExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
    case 'Product':
      setProductExclusion(isExclusion, discount, setDiscount, isConstraint, constraintIndex);
      break;
  }
};

const setStrainExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newStrainRestriction = constraint.Restrictions?.Strain
      ? constraint.Restrictions?.Strain
      : { IsExclusion: false, RestrictionIds: [] };
    newStrainRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Strain = newStrainRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction: Restrictions = discount.Reward.Restrictions;
    const newStrainRestriction = discount.Reward.Restrictions?.Strain
      ? discount.Reward.Restrictions?.Strain
      : { IsExclusion: false, RestrictionIds: [] };
    newStrainRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Strain = newStrainRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setCategoryExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newCategoryRestriction = constraint.Restrictions?.Category
      ? constraint.Restrictions?.Category
      : { IsExclusion: false, RestrictionIds: [] };
    newCategoryRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Category = newCategoryRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newCategoryRestriction = discount.Reward.Restrictions?.Category
      ? discount.Reward.Restrictions?.Category
      : { IsExclusion: false, RestrictionIds: [] };
    newCategoryRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Category = newCategoryRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setBrandExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newBrandRestriction = constraint.Restrictions?.Brand
      ? constraint.Restrictions?.Brand
      : { IsExclusion: false, RestrictionIds: [] };
    newBrandRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Brand = newBrandRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newBrandRestriction = discount.Reward.Restrictions?.Brand
      ? discount.Reward.Restrictions?.Brand
      : { IsExclusion: false, RestrictionIds: [] };
    newBrandRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Brand = newBrandRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setVendorExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newVendorRestriction = constraint.Restrictions?.Vendor
      ? constraint.Restrictions?.Vendor
      : { IsExclusion: false, RestrictionIds: [] };
    newVendorRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Vendor = newVendorRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newVendorRestriction = discount.Reward.Restrictions?.Vendor
      ? discount.Reward.Restrictions?.Vendor
      : { IsExclusion: false, RestrictionIds: [] };
    newVendorRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Vendor = newVendorRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setWeightExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newWeightRestriction = constraint.Restrictions?.Weight
      ? constraint.Restrictions?.Weight
      : { IsExclusion: false, RestrictionIds: [] };
    newWeightRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Weight = newWeightRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newWeightRestriction = discount.Reward.Restrictions?.Weight
      ? discount.Reward.Restrictions?.Weight
      : { IsExclusion: false, RestrictionIds: [] };
    newWeightRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Weight = newWeightRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setTagExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newTagRestriction = constraint.Restrictions?.Tag
      ? constraint.Restrictions?.Tag
      : { IsExclusion: false, RestrictionIds: [] };
    newTagRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Tag = newTagRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newTagRestriction = discount.Reward.Restrictions?.Tag
      ? discount.Reward.Restrictions?.Tag
      : { IsExclusion: false, RestrictionIds: [] };
    newTagRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Tag = newTagRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setInventoryTagExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newTagRestriction = constraint.Restrictions?.InventoryTag
      ? constraint.Restrictions?.InventoryTag
      : { IsExclusion: false, RestrictionIds: [] };
    newTagRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.InventoryTag = newTagRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newTagRestriction = discount.Reward.Restrictions?.InventoryTag
      ? discount.Reward.Restrictions?.InventoryTag
      : { IsExclusion: false, RestrictionIds: [] };
    newTagRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.InventoryTag = newTagRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setPricingTierExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newTierRestriction = constraint.Restrictions?.Tier
      ? constraint.Restrictions?.Tier
      : { IsExclusion: false, RestrictionIds: [] };
    newTierRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Tier = newTierRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newTierRestriction = discount.Reward.Restrictions?.Tier
      ? discount.Reward.Restrictions?.Tier
      : { IsExclusion: false, RestrictionIds: [] };
    newTierRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Tier = newTierRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};

const setProductExclusion = (
  isExclusion: string,
  discount: Discount,
  setDiscount: (discount: Discount) => void,
  isConstraint: boolean,
  constraintIndex?: number
) => {
  if (isConstraint) {
    const newConstraints = _.cloneDeep(discount.Constraints);
    const constraint = newConstraints[constraintIndex];
    const newRestriction = constraint.Restrictions;
    const newProductRestriction = constraint.Restrictions?.Product
      ? constraint.Restrictions?.Product
      : { IsExclusion: false, RestrictionIds: [] };
    newProductRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Product = newProductRestriction;
    newConstraints[constraintIndex] = {
      ...constraint,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Constraints: newConstraints });
  } else {
    const newRestriction = discount.Reward.Restrictions;
    const newProductRestriction = discount.Reward.Restrictions?.Product
      ? discount.Reward.Restrictions?.Product
      : { IsExclusion: false, RestrictionIds: [] };
    newProductRestriction.IsExclusion = isExclusion === 'yes';
    newRestriction.Product = newProductRestriction;
    const newReward = {
      ...discount.Reward,
      Restrictions: newRestriction,
    };
    setDiscount({ ...discount, Reward: newReward });
  }
};
