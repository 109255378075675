import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ControlBar, ControlGroup, ControlSearchBox, RefreshButton } from 'src/app/components/lib/control-bar';
import { DataGrid, type GridColDef } from 'src/app/components/lib/table';
import { Type } from 'src/app/components/lib/type';
import { CustomerSegmentDiscounts } from 'src/app/constants/table-names';
import {
  apiDateToMoment,
  getDiscountValidity,
  getDiscountValiditySortText,
  discountValidityToStatusElement,
} from 'src/app/pages/marketing/discounts/discount-helpers';
import { useGetSegmentDiscountsQuery } from 'src/app/queries/segment/get-segment-discounts';

export function AssociatedDiscounts() {
  const { segmentId = '' } = useParams<{ segmentId: string }>();
  const {
    data: discounts = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetSegmentDiscountsQuery({ segmentId: Number(segmentId) });
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const columns: GridColDef[] = [
    { field: 'DiscountId', hide: true },
    {
      field: 'DiscountName',
      headerName: 'Discount name',
      renderCell: ({ value }) => <Type px={14}>{value}</Type>,
    },
    {
      field: 'Status',
      headerName: 'Status',
      minWidth: 122,
      valueGetter: ({ row }) =>
        getDiscountValiditySortText(
          getDiscountValidity({
            validFrom: apiDateToMoment(row.ValidDateFrom),
            validTo: apiDateToMoment(row.ValidDateTo),
          })
        ),
      renderCell: ({ row }) =>
        discountValidityToStatusElement(
          getDiscountValidity({
            validFrom: apiDateToMoment(row.ValidDateFrom),
            validTo: apiDateToMoment(row.ValidDateTo),
          })
        ),
    },
    {
      field: 'ValidDateFrom',
      headerName: 'Start date',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: 'ValidDateTo',
      headerName: 'End date',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  const filteredDiscounts = useMemo(() => {
    if (!searchTerm) {
      return discounts;
    }

    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const fieldsToSearch = ['DiscountName'];

    return discounts.filter((discount) =>
      fieldsToSearch.some((field) => discount[field]?.toLowerCase().includes(lowercasedSearchTerm))
    );
  }, [searchTerm, discounts]);

  return (
    <>
      <ControlBar>
        <ControlGroup grow>
          <ControlSearchBox placeholder='Search by discount name...' onChange={(value) => setSearchTerm(value)} />
          <RefreshButton isFetching={isFetching} onClick={refetch} />
        </ControlGroup>
      </ControlBar>
      <DataGrid
        columns={columns}
        getRowId={(row) => row.DiscountId}
        height='100%'
        loading={isLoading}
        name={CustomerSegmentDiscounts}
        rows={filteredDiscounts}
      />
    </>
  );
}
